<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
<div>
  <b-row>
        <b-card class="mb-1 col-md-4" :title="'Commande #'+order.id">
         
          <b-card-text>
            <b>Votre commande est confirmée et va être passée en production</b>
          </b-card-text>

         
          <b-button
            @click="downloadFiles()"
            variant="dark"
          >
            Download files
          </b-button>
        
           
        </b-card>

        <b-card class="mb-1 col-md-8" title="Détails commande">

       
           <b-card-text v-for="(order_item, index) in cart_items" :key="order_item.id">
            <img :src="photos[index]" width="120" /><br />
            <b>Nom du produit : </b>
            <span v-if="order_item.product_variation_item">
              {{order_item.glass.name}} ({{order_item.product_variation_item.name}})
            </span>
            <span v-else>
              {{order_item.glass.name}}
            </span>
            <br />
            <b>Quantité : </b>{{order_item.quantity}}<br />
            <div v-if="order_item.glass.type=='collection'">
              <b>Informations personnalisation</b><br />
              <!--Type de verre : {{order_item.glass_type.name}}<br />-->
              Option de verre : {{order_item.glass_option.name}}<br />
              Couleur : {{order_item.color.name}} (ref : {{ order_item.color.supplier_reference }})<br />
              <span v-if="order_item.tint">
              Teinte de verres : {{order_item.tint.name}}<br />
              </span>
              Branche : {{order_item.branch.name}}<br />
              
              <span v-if="order_item.refinement">Affinement : {{order_item.refinement.name}}<br /></span>
              <span v-if="order_item.processing">Traitement : {{order_item.processing.name}}<br /></span>
              <div v-if="order_item.scan!=null">
                Scan : {{order_item.scan.name}} (<a @click="$router.push({name:'scanDetail', params: { id: order_item.scan.id }})">Voir</a>)<br />
                
              </div>
              <div v-if="order_item.prescription!=null">
                Ordonnance : {{order_item.prescription.name}} (<a @click="displayPrescription(order_item.prescription.id)">Voir</a>)<br />
              </div>
             
            </div>
            <b>Sous-total : </b>{{order_item.amount}}€<br /><br />
          </b-card-text>
        
          
          <b-card-text v-if="order.promotion!=null">
            <b>Promotion : </b>-{{order.promotion.amount}}%
          </b-card-text>
          <b-card-text>
            <b>TOTAL : </b>
            <span v-if="order.promotion!=null">{{order.amount - ((order.amount/100)*order.promotion.amount).toFixed(2)}}€</span>
            <span v-else>{{order.amount}}€</span>
          </b-card-text>
          
        </b-card>
   

  </b-row>
  <b-row>
        <ScanPopup :scan_id="currentScan" v-if="displayScanPopup" />
        <PrescriptionPopup :prescription_id="currentPrescription" v-if="displayPrescriptionPopup" />
  </b-row>
</div>
</b-overlay>
</template>

<script>
import {BModal, BButton,BOverlay, VBModal, BRow, BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest, Woocommerce} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'
import ScanPopup from '../../components/ScanPopup.vue'
import PrescriptionPopup from '../../components/PrescriptionPopup.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import emitter from 'tiny-emitter/instance'

export default {
  components: {
    BCard,
    BCardText,
    VueGoodTable,
    BAvatar,
    BOverlay,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton,
    BRow,
    ScanPopup,
    PrescriptionPopup
  },
  data() {
    return {
      order:null,
      refreshed:localStorage.order_review_reloaded,
      displayStatusSelection:false,
      statusSelected:null,
      statusOptions:['pending','on-hold','failed','completed','processing','cancelled','refunded','trash'],
      pageLength: 10,
      dir: false,
      photos:null,
      currentScan:null,
      currentPrescription:null,
      displayPrescriptionPopup:false,
      displayScanPopup:false,
      loading:true,
      cart_items:null,
      trackingNumber:''
    }
  },
  methods:{
    displayScan(id) {
      this.currentScan=id
      this.displayScanPopup=true
    },
    displayPrescription(id) {
      this.currentPrescription=id
      this.displayPrescriptionPopup=true
    },
    async getOrder() {
      let order=await APIRequest.detail('orders',this.$route.params.id)
      this.order={}
      this.order=order
      console.log('order')
      console.log(this.order)
      
      let cart_items_new=[]
        for(let i=0;i<this.order.order_items.length;i++) {
        let item = await APIRequest.detail('order-items',this.order.order_items[i].id) 
        cart_items_new.push(item)
      }
    
      this.cart_items=cart_items_new
      
      if(!this.order.user)
      {
        this.order.user={}
        this.order.user.name=''
        this.order.user.surname='Inconnu'
      }
      this.photos=[]
      for(let i=0;i<this.cart_items.length;i++)
      {
        if(this.cart_items[i].product_variation_item!=null)
        {
          var photos=this.cart_items[i].product_variation_item.photos;
        }
        else
        {
          var photos=this.cart_items[i].glass.photos;
        }
        let photo=photos[0];   
        if(photo!='')
        {
          this.photos[i]=photo
        }
      }
    },
    downloadFiles() {
      var str = this.order.file1 // Export the scene
      var blob = new Blob( [str], { type : 'text/plain' } ); // Generate Blob from the string
      console.log(str)
      var link = document.createElement('a');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.href = URL.createObjectURL(blob);
      link.download = 'Scene.stl';
      link.click();

      var str2 = this.order.file2; // Export the scene
      var blob2 = new Blob( [str2], { type : 'text/plain' } ); // Generate Blob from the string
      //saveAs( blob, 'file.stl' ); //Save the Blob to file.stl
 console.log(str2)
      var link2 = document.createElement('a');
      link2.style.display = 'none';
      document.body.appendChild(link2);
      link2.href = URL.createObjectURL(blob2);
      link2.download = 'Scene 2.stl';
      link2.click();

      var str3 = this.order.file3; // Export the scene
      var blob3 = new Blob( [str3], { type : 'text/plain' } ); // Generate Blob from the string
      var link3 = document.createElement('a');
       console.log(str3)
      link3.style.display = 'none';
      document.body.appendChild(link3);
      link3.href = URL.createObjectURL(blob3);
      link3.download = 'Scene 3.stl';
      link3.click();
    }
    
  },
  watch:{
    
  },
  async mounted()
  {
   
      console.log('reloaddded')
      this.loading=true
      emitter.on('hide-popup-scan', function () {
        this.displayScanPopup=false
      }, this);
      emitter.on('hide-popup-prescription', function () {
        this.displayPrescriptionPopup=false
      }, this);
      await this.getOrder()
      this.loading=false
    
    
   
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
